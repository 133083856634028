import { useState, type HTMLProps } from "react";
import { useForm } from "react-hook-form";
import { trpc } from "../utils";

function TwilioRow({
  id,
  name,
  accountSID,
  onUpdate,
}: {
  id: string;
  name: string;
  accountSID: string;
  onUpdate: () => void;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const accountForm = useForm<{
    id: string;
    name: string;
    accountSID: string;
    authToken: string;
  }>({ defaultValues: { name, accountSID, authToken: "", id } });

  const deleteSubAccount = trpc.twilio.delete.useMutation({
    onSettled() {
      onUpdate();
    },
  });
  const updateSubAccount = trpc.twilio.update.useMutation({
    onSettled() {
      setIsEditing(false);
      onUpdate();
    },
  });

  const onSubmit = accountForm.handleSubmit(async (data) => {
    updateSubAccount.mutate(data);
  });

  return (
    <tr>
      {isEditing ? (
        <>
          <td>
            <input
              type="text"
              placeholder="Name"
              className="w-full max-w-xs input input-bordered"
              {...accountForm.register("name")}
            />
          </td>
          <td>
            <input
              type="text"
              placeholder="Name"
              className="w-full max-w-xs input input-bordered"
              {...accountForm.register("accountSID")}
            />
          </td>
          <td>
            <input
              type="text"
              placeholder="Name"
              className="w-full max-w-xs input input-bordered"
              {...accountForm.register("authToken")}
            />
          </td>
          <td className="flex gap-2">
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </button>
            <form onSubmit={onSubmit}>
              <button type="submit" className="btn btn-sm btn-primary">
                Submit
              </button>
            </form>
          </td>
        </>
      ) : (
        <>
          <td>{name}</td>
          <td>{accountSID}</td>
          <td>***</td>
          <td className="flex gap-2">
            <button
              type="button"
              onClick={() => setIsEditing(true)}
              className="btn btn-sm btn-warning"
            >
              Edit
            </button>
            <button
              type="button"
              onClick={() => {
                if (window.confirm("Are you sure?")) {
                  deleteSubAccount.mutate({ id });
                }
              }}
              className="btn btn-sm btn-error"
            >
              Delete
            </button>
          </td>
        </>
      )}
    </tr>
  );
}

export default function Twilio({ ...params }: {} & HTMLProps<HTMLDivElement>) {
  const getSubAccounts = trpc.twilio.retrieve.useQuery();

  const createSubAccount = trpc.twilio.create.useMutation({
    onSettled() {
      getSubAccounts.refetch();
    },
  });
  const userForm = useForm<{
    name: string;
    accountSid: string;
    authToken: string;
  }>();

  return (
    <div {...params}>
      <div className="overflow-x-auto">
        <table className="table">
          {/* head */}
          <thead>
            <tr>
              <th>Name</th>
              <th>Account SID</th>
              <th>Account Secret</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {/* row 1 */}
            {getSubAccounts.data?.map((subAccount) => (
              <TwilioRow
                key={subAccount.id}
                {...subAccount}
                onUpdate={() => getSubAccounts.refetch()}
              />
            ))}
          </tbody>
        </table>
        <form
          className="flex gap-2 mt-4"
          onSubmit={userForm.handleSubmit((data) => {
            userForm.reset();
            createSubAccount.mutate(data);
          })}
        >
          <input
            type="text"
            placeholder="Account name"
            className="w-full max-w-xs input input-bordered"
            {...userForm.register("name", { required: true })}
          />
          <input
            type="text"
            placeholder="Account SID"
            className="w-full max-w-xs input input-bordered"
            {...userForm.register("accountSid", { required: true })}
          />
          <input
            type="text"
            placeholder="Account secret"
            className="w-full max-w-xs input input-bordered"
            {...userForm.register("authToken", { required: true })}
          />
          <button className="btn" type="submit">
            Add sub account
          </button>
        </form>
      </div>
    </div>
  );
}
