import dayjs from "dayjs";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { parsePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import useRequiredParam from "../hooks/useRequiredParam";
import { trpc } from "../utils";
import PaymentForm from "./paymentForm";

export type PaymentFormType = {
  firstName: string;
  lastName: string;
  phone: string;
  creditCard: string;
  cvv: string;
  expiration: string;
};

const formatToDollars = (number: number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(number);
};

export default function Registration() {
  const confirmationId = useRequiredParam<{ confirmationId: string }>(
    "confirmationId",
  );
  const registration = trpc.registration.get.useQuery({
    confirmationId: confirmationId,
  });
  const paymentForm = useForm<PaymentFormType>();
  const submitPayment = trpc.registration.submitPayment.useMutation();

  useEffect(() => {
    if (registration.data) {
      paymentForm.setValue("firstName", registration.data.firstName);
      paymentForm.setValue("lastName", registration.data.lastName);
      const phone = parsePhoneNumber(registration.data.phone);
      paymentForm.setValue("phone", phone?.number ?? "");
    }
  }, [registration.data, paymentForm.setValue]);

  if (registration.error) {
    return <div>{registration.error.message}</div>;
  }

  if (registration.isLoading) {
    return <div />;
  }

  if (!registration.data) {
    return <div>Not found</div>;
  }

  const onSubmit = async (data: PaymentFormType) => {
    await submitPayment.mutateAsync({
      confirmationId: confirmationId,
      firstName: data.firstName,
      lastName: data.lastName,
      phone: data.phone,
    });

    registration.refetch();
  };

  return (
    <div className="p-4">
      <div className="mb-4 text-2xl">
        Confirmation{" "}
        <span className="font-extrabold">
          {registration.data.confirmationId}
        </span>
      </div>
      <div className="font-semibold">
        {dayjs(registration.data.startDate).format("MMM D, YYYY")} -{" "}
        {dayjs(registration.data.endDate).format("MMM D, YYYY")}
      </div>
      <div>
        {registration.data.numberOfAdults} adult
        {registration.data.numberOfAdults === 1 ? "" : "s"}
        {!!registration.data.numberOfChildren && (
          <>
            , {registration.data.numberOfChildren}{" "}
            {registration.data.numberOfChildren === 1 ? "child" : "children"}
          </>
        )}
      </div>
      {registration.data.cancelled && (
        <div className="font-bold">Cancelled</div>
      )}
      <div>{registration.data.roomType.name}</div>
      <div className="mt-2 text-lg font-bold">
        {formatToDollars(registration.data.cost)}
      </div>
      {registration.data.payment?.submittedAt ? (
        <div>
          <div className="font-bold">Payment successfully submitted</div>
        </div>
      ) : (
        <FormProvider {...paymentForm}>
          <form
            onSubmit={paymentForm.handleSubmit(onSubmit)}
            className="flex flex-col gap-4 mt-4"
          >
            <PaymentForm isPending={submitPayment.isPending} />
          </form>
        </FormProvider>
      )}
    </div>
  );
}
