import { type HTMLProps, useEffect, useState } from "react";
import useRequiredParam from "../hooks/useRequiredParam";
import { trpc } from "../utils";

export default function Tools(params: HTMLProps<HTMLDivElement>) {
  const hotelId = useRequiredParam<{ hotelId: string }>("hotelId");
  const updateTools = trpc.tools.update.useMutation();
  const getTools = trpc.tools.retrieve.useQuery(
    { hotelId },
    {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );
  const [tools, setTools] = useState<string[]>([]);

  useEffect(() => {
    if (getTools.data) {
      setTools(getTools.data.tools);
    }
  }, [getTools.data]);

  return (
    getTools.data && (
      <div {...params}>
        <div className="flex flex-col gap-4">
          <div>
            {getTools.data.allTools.map((tool, index) => (
              <div className="form-control max-w-60" key={tool}>
                <label className="cursor-pointer label">
                  <span className="label-text">{tool}</span>
                  <input
                    type="checkbox"
                    className="toggle"
                    key={index}
                    checked={tools.includes(tool)}
                    onChange={(e) => {
                      setTools((prev) => {
                        const newTools = e.target.checked
                          ? [...prev, tool]
                          : prev.filter((t) => t !== tool);

                        updateTools.mutate({ hotelId, tools: newTools });

                        return newTools;
                      });
                    }}
                  />
                </label>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  );
}
