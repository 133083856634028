import Payment from "payment";
import { Controller, useFormContext } from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import type { PaymentFormType } from "./registration";

function clearNumber(value = "") {
  return value.replace(/\D+/g, "");
}

export default function PaymentForm({ isPending }: { isPending: boolean }) {
  const paymentForm = useFormContext<PaymentFormType>();

  const handleCVVChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const cvv = evt.target.value;
    const clearValue = clearNumber(cvv);
    let maxLength = 4;

    const cardNumber = paymentForm.getValues("creditCard");

    if (cardNumber) {
      const issuer = Payment.fns.cardType(cardNumber);
      maxLength = issuer === "amex" ? 4 : 3;
    }

    paymentForm.setValue("cvv", clearValue.slice(0, maxLength));
  };

  const handleExpirationChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const value = evt.target.value;
    let clearValue = clearNumber(value);

    if (clearValue.length >= 3) {
      clearValue = `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }

    paymentForm.setValue("expiration", clearValue);
  };

  const handleCreditCardChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const value = evt.target.value;
    if (!value) {
      return value;
    }

    const issuer = Payment.fns.cardType(value);
    const clearValue = clearNumber(value);
    let nextValue: string;

    switch (issuer) {
      case "amex":
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          10,
        )} ${clearValue.slice(10, 15)}`;
        break;
      case "dinersclub":
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          10,
        )} ${clearValue.slice(10, 14)}`;
        break;
      default:
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          8,
        )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
        break;
    }

    paymentForm.setValue("creditCard", nextValue.trim());
  };

  return (
    <>
      <div className="grid gap-4 sm:grid-cols-2">
        <label className="w-full form-control">
          <div className="label">
            <span className="label-text">First name</span>
          </div>
          <input
            className="input input-bordered"
            {...paymentForm.register("firstName", { required: true })}
            placeholder="First name"
          />
        </label>

        <label className="w-full form-control">
          <div className="label">
            <span className="label-text">Last name</span>
          </div>
          <input
            className="input input-bordered"
            {...paymentForm.register("lastName", { required: true })}
            placeholder="Last name"
          />
        </label>
      </div>

      <label className="w-full form-control">
        <div className="label">
          <span className="label-text">Phone</span>
        </div>
        <Controller
          name="phone"
          control={paymentForm.control}
          render={({ field }) => (
            <PhoneInput
              {...field}
              defaultCountry="US"
              international={false}
              className="input input-bordered"
              placeholder="Enter phone number"
            />
          )}
        />
      </label>

      <label className="w-full form-control">
        <div className="label">
          <span className="label-text">Credit card</span>
        </div>
        <input
          className="input input-bordered"
          required
          placeholder="Credit card"
          pattern="[\d| ]{16,22}"
          {...paymentForm.register("creditCard", { required: true })}
          onChange={handleCreditCardChange}
        />
      </label>

      <div className="grid gap-4 sm:grid-cols-2">
        <label className="w-full form-control">
          <div className="label">
            <span className="label-text">CVV</span>
          </div>
          <input
            className="input input-bordered"
            required
            placeholder="CVV"
            pattern="\d{3,4}"
            {...paymentForm.register("cvv", { required: true })}
            onChange={handleCVVChange}
          />
        </label>

        <label className="w-full form-control">
          <div className="label">
            <span className="label-text">Expiration</span>
          </div>
          <input
            type="tel"
            className="input input-bordered"
            required
            placeholder="Expiration"
            pattern="\d\d/\d\d"
            {...paymentForm.register("expiration", { required: true })}
            onChange={handleExpirationChange}
          />
        </label>
      </div>

      <button className="btn btn-outline" type="submit" disabled={isPending}>
        Submit
      </button>
    </>
  );
}
