import type { inferRouterOutputs } from "@trpc/server";
import { useEffect, useState } from "react";
import SuperJSON from "superjson";
import type { AppRouter } from "../../server/routes/trpc";

type RouterOutput = inferRouterOutputs<AppRouter>;
type Registration = RouterOutput["registration"]["getAll"][0];

export type HistoryOutput = RouterOutput["history"]["get"][0];

type EventData = {
  history?: HistoryOutput;
  registration?: Registration;
};

export default function useServerStream<T extends keyof EventData>({
  hotelId,
  eventType,
  onConnect,
}: {
  hotelId: string;
  eventType: T;
  onConnect?: () => void;
}): EventData[T][] {
  const [stream, setStream] = useState<EventData[T][]>([]);

  useEffect(() => {
    const eventSource = new EventSource(`/stream/${hotelId}`);

    eventSource.onopen = () => {
      onConnect?.();
    };

    eventSource.onmessage = (event) => {
      try {
        const parsedData: EventData = SuperJSON.parse(event.data);

        // Type guard to ensure the parsedData is of the correct type
        if (eventType === "registration" && parsedData.registration) {
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
          setStream((prev) => [...prev, parsedData.registration as any]);
        } else if (eventType === "history" && parsedData.history) {
          // biome-ignore lint/suspicious/noExplicitAny: <explanation>
          setStream((prev) => [...prev, parsedData.history as any]);
        }
      } catch (e) {
        console.error("Failed to parse event data", e);
      }
    };

    return () => {
      eventSource.close();
    };
  }, [onConnect, eventType, hotelId]);

  return stream;
}
