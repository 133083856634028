import { type SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import timezones from "timezones-list";
import { trpc } from "../utils";

type HotelForm = {
  name: string;
  address: string;
  phone: string;
  lat: string;
  lng: string;
  forwardPhone?: string;
  forwardExtension?: string;
  timezone: string;
  initialMessage: string;
};

export default function Create() {
  const { register, handleSubmit } = useForm<HotelForm>();
  const createHotel = trpc.hotel.create.useMutation();
  const navigate = useNavigate();

  const onCreateHotel: SubmitHandler<HotelForm> = async (data) => {
    const hotel = await createHotel.mutateAsync(data);
    navigate(`/hotel/${hotel.id}`);
  };

  return (
    <div className="p-8">
      <div className="text-2xl font-bold">Create a new hotel</div>
      <form
        onSubmit={handleSubmit(onCreateHotel)}
        className="flex flex-col gap-4"
      >
        <div>
          <div className="font-bold">Name</div>
          <input
            className="w-full h-10 input input-bordered"
            {...register("name", {
              required: "hotel name is required",
            })}
          />
        </div>

        <div>
          <div className="font-bold">Timezone</div>
          <select
            className="w-full select select-bordered"
            {...register("timezone", {
              required: "hotel timezone is required",
            })}
          >
            <option value="">Select timezone</option>
            {timezones.map((option) => (
              <option key={option.tzCode} value={option.tzCode}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <div>
          <div className="font-bold">Address</div>
          <input
            className="w-full input input-bordered"
            {...register("address", {
              required: "hotel address is required",
            })}
          />
        </div>
        <div>
          <div className="font-bold">Phone</div>
          <input
            placeholder="+15551231212"
            className="w-full input input-bordered"
            {...register("phone", {
              required: "Hotel phone is required",
            })}
          />
        </div>
        <div>
          <div className="font-bold">Front desk phone</div>
          <div>Will transfer calls here</div>
          <input
            placeholder="+15551231212"
            className="w-full input input-bordered"
            {...register("forwardPhone")}
          />
        </div>
        <div>
          <div className="font-bold">Front desk extension</div>
          <div>(w is a half second pause ie ww123)</div>
          <input
            className="w-full input input-bordered"
            {...register("forwardExtension")}
          />
        </div>
        <div>
          <div className="font-bold">Initial prompt message</div>
          <input
            className="w-full input input-bordered"
            placeholder="Welcome to our hotel, how can we help you?"
            {...register("initialMessage")}
          />
        </div>
        <div className="flex gap-4">
          <div>
            <div className="font-bold">Lat</div>
            <input
              className="w-full input input-bordered"
              {...register("lat", {
                required: "Hotel lat is required",
              })}
            />
          </div>
          <div>
            <div className="font-bold">Lng</div>
            <input
              className="w-full input input-bordered"
              {...register("lng", {
                required: "Hotel long is required",
              })}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <button className="btn btn-outline btn-primary" type="submit">
            Create hotel
          </button>
        </div>
      </form>
    </div>
  );
}
