import { useParams } from "react-router";

const useRequiredParam = <Params extends Record<string, string>>(
  param: keyof Params,
): string => {
  const params = useParams() as Params;

  const value = params[param];
  if (!value) {
    throw new Error(`Missing required parameter: ${param as string}`);
  }

  return value;
};

export default useRequiredParam;
