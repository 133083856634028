import clsx from "clsx";
import dayjs from "dayjs";
import { type HTMLProps, useEffect, useState } from "react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import { type SubmitHandler, useForm } from "react-hook-form";
import { trpc } from "../utils";

export type TransferForm = {
  systemPrompt: string;
  userPrompt: string;
  userMessage: string;
  promptUpdateReason: string;
};

export default function TransferPrompt({
  onReset,
  ...params
}: {
  onReset: () => void;
} & HTMLProps<HTMLDivElement>) {
  const updatePrompts = trpc.transferPrompt.update.useMutation();
  const getPrompts = trpc.transferPrompt.retrieve.useQuery(undefined);
  const [showHistory, setShowHistory] = useState(false);
  const [previousVersion, setPreviousVersion] = useState<number | null>(null);
  const [saving, setSaving] = useState(false);
  const getPreviousVersion = trpc.transferPrompt.getPreviousVersion.useQuery(
    {
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      id: previousVersion!,
    },
    {
      enabled: previousVersion !== null,
    },
  );
  const {
    register: registerForm,
    handleSubmit,
    setValue,
    watch,
    formState: { isDirty },
  } = useForm<TransferForm>({
    defaultValues: {
      userMessage: "",
      systemPrompt: "",
      userPrompt: "",
    },
    mode: "onChange",
  });

  const systemPrompt = watch("systemPrompt");
  const userPrompt = watch("userPrompt");
  const userMessage = watch("userMessage");

  useEffect(() => {
    if (!isDirty && getPrompts.data) {
      const { userMessage, systemPrompt, userPrompt } = getPrompts.data.prompts;
      setValue("userMessage", userMessage);
      setValue("systemPrompt", systemPrompt);
      setValue("userPrompt", userPrompt);
    }
  }, [getPrompts.data, setValue, isDirty]);

  const onUpdatePrompts: SubmitHandler<TransferForm> = async (data) => {
    await updatePrompts.mutateAsync({
      ...data,
    });

    // let's reset everything
    onReset();
  };

  return (
    <div {...params}>
      <form
        onSubmit={handleSubmit(onUpdatePrompts)}
        className="flex flex-col gap-4"
      >
        {saving && (
          <div className="flex flex-col gap-4">
            <div>
              <div className="font-bold">User transfer message:</div>
              <div className="text-sm">
                <ReactDiffViewer
                  oldValue={getPrompts.data?.prompts.userMessage ?? ""}
                  newValue={userMessage}
                  splitView={true}
                  compareMethod={DiffMethod.WORDS}
                />
              </div>
            </div>
            <div>
              <div className="font-bold">Transfer System prompt:</div>
              <div className="text-sm">
                <ReactDiffViewer
                  oldValue={getPrompts.data?.prompts.systemPrompt ?? ""}
                  newValue={systemPrompt}
                  splitView={true}
                  compareMethod={DiffMethod.WORDS}
                />
              </div>
            </div>
            <div>
              <div className="font-bold">Transfer User prompt:</div>
              <div className="text-sm">
                <ReactDiffViewer
                  oldValue={getPrompts.data?.prompts.userPrompt ?? ""}
                  newValue={userPrompt}
                  splitView={true}
                  compareMethod={DiffMethod.WORDS}
                />
              </div>
            </div>
            <div>
              <textarea
                className="w-full h-24 textarea textarea-bordered"
                placeholder="Reason for updating the prompt"
                {...registerForm("promptUpdateReason", {
                  required: "System prompt is required",
                })}
              />
            </div>
            <div className="flex gap-4">
              <button
                type="button"
                className="btn btn-outline btn-warning"
                onClick={() => setSaving(false)}
              >
                cancel
              </button>
              <button type="submit" className="btn btn-outline btn-primary">
                Submit
              </button>
            </div>
          </div>
        )}

        {!saving && (
          <>
            <div>
              <div className="font-bold">User transfer message</div>
              <input
                className="w-full h-10 input input-bordered"
                {...registerForm("userMessage", {
                  required: "user message is required",
                })}
                placeholder="Please wait while we transfer you to someone that can assist"
              />
            </div>

            <div>
              <div className="font-bold">Transfer System prompt</div>
              <textarea
                className="w-full textarea textarea-bordered h-52"
                {...registerForm("systemPrompt", {
                  required: "System prompt is required",
                })}
                placeholder="You are a telephone operator at a hotel. A guest has called the front desk and asked to be transferred to a human agent. You are calling the front desk agent and this the next input will be the history of your interactions with the user"
              />
            </div>
            <div>
              <div className="font-bold">Transfer User prompt</div>
              <textarea
                className="w-full textarea textarea-bordered h-52"
                {...registerForm("userPrompt", {
                  required: "User prompt is required",
                })}
                placeholder="Give the agent a friendly greeting, tell them you're an AI agent with a user on the line, and then summarize the conversation extremely briefly making sure to highlight why the user requested to be transferred. Then tell the agent you are going to transfer them now"
              />
            </div>

            <button
              type="button"
              className="btn"
              onClick={() => setShowHistory((prev) => !prev)}
            >
              {showHistory ? "Hide history" : "Show history"}
            </button>

            {showHistory && (
              <div className="flex gap-4">
                <div>
                  <div
                    className={clsx("py-2 text-sm cursor-pointer px-1", {
                      " text-white bg-black": previousVersion === null,
                    })}
                    onClick={() => setPreviousVersion(null)}
                  >
                    Current
                  </div>
                  {getPrompts.data?.history.map((prompt) => (
                    <div key={prompt.id}>
                      <div
                        className={clsx(
                          "px-1 py-2 text-sm border-t border-b cursor-pointer whitespace-nowrap",
                          {
                            " text-white bg-black":
                              prompt.id === previousVersion,
                          },
                        )}
                        onClick={async () => {
                          setPreviousVersion(prompt.id);
                        }}
                      >
                        {dayjs(prompt.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex flex-col w-full gap-4">
                  <div>
                    {getPreviousVersion.data?.prompts.promptUpdateReason}{" "}
                    {getPreviousVersion.data?.prompts.updatedBy?.email && (
                      <>({getPreviousVersion.data?.prompts.updatedBy?.email})</>
                    )}
                  </div>
                  <div>
                    <div className="font-bold">User transfer message:</div>
                    <div className="text-sm">
                      <ReactDiffViewer
                        oldValue={
                          getPreviousVersion.data?.previous.userMessage ??
                          getPrompts.data?.prompts.userMessage ??
                          ""
                        }
                        newValue={
                          getPreviousVersion.data?.prompts.userMessage ??
                          userMessage
                        }
                        splitView={true}
                        compareMethod={DiffMethod.WORDS}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="font-bold">Transfer System prompt:</div>
                    <div className="text-sm">
                      <ReactDiffViewer
                        oldValue={
                          getPreviousVersion.data?.previous.systemPrompt ??
                          getPrompts.data?.prompts.systemPrompt ??
                          ""
                        }
                        newValue={
                          getPreviousVersion.data?.prompts.systemPrompt ??
                          systemPrompt
                        }
                        splitView={true}
                        compareMethod={DiffMethod.WORDS}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="font-bold">Transfer User prompt:</div>
                    <div className="text-sm">
                      <ReactDiffViewer
                        oldValue={
                          getPreviousVersion.data?.previous.userPrompt ??
                          getPrompts.data?.prompts.userPrompt ??
                          ""
                        }
                        newValue={
                          getPreviousVersion.data?.prompts.userPrompt ??
                          userPrompt
                        }
                        compareMethod={DiffMethod.WORDS}
                        splitView={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="flex gap-4">
              <button
                className="btn btn-outline btn-primary"
                type="button"
                onClick={() => {
                  setSaving(true);
                  getPrompts.refetch();
                }}
              >
                Update prompts
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  );
}
