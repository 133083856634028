import clsx from "clsx";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import History from "./history";
import useRequiredParam from "./hooks/useRequiredParam";
import Hotel from "./hotel/hotel";
import Login from "./login";
import Prompts from "./prompts/prompts";
import Twilio from "./twilio/twilio";
import Users from "./users/users";
import { formatPhoneNumber, trpc } from "./utils";

export type HotelForm = {
  name: string;
  address: string;
  phone: string;
  lat: string;
  lng: string;
  forwardPhone?: string;
  forwardExtension?: string;
  timezone: string;
  twilioSubAccountId: string;
};

export type MessageForm = {
  content: string;
  fromNumber: string;
};

type TAB = "chat" | "prompts" | "hotel" | "history" | "users" | "twilio";

export default function App() {
  const hotelId = useRequiredParam<{ hotelId: string }>("hotelId");
  const register = trpc.register.useMutation();
  const sendMessage = trpc.sendMessage.useMutation();
  const [callSid, setCallSid] = useState<string>("");
  const [history, setHistory] = useState<
    { role: string; content: string | null }[]
  >([]);
  const hotelForm = useForm<HotelForm>();
  const messageForm = useForm<MessageForm>();
  const [tab, setTab] = useState<TAB>("chat");
  const [incomingPhoneId, setIncomingPhoneId] = useState<string | null>(null);
  const createIncomingPhone = trpc.createCall.useMutation();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const setup = async () => {
      const { hotel, id } = await register.mutateAsync({ hotelId });
      setCallSid(id);
      setHistory([
        { role: "assistant", content: hotel.hotelPrompts?.[0]?.initialMessage },
      ]);

      hotelForm.setValue("name", hotel.name);
      hotelForm.setValue("address", hotel.address);
      hotelForm.setValue("phone", hotel.phone);
      hotelForm.setValue("lat", hotel.lat);
      hotelForm.setValue("lng", hotel.lng);
      hotelForm.setValue("forwardPhone", hotel.forwardPhone ?? "");
      hotelForm.setValue("forwardExtension", hotel.forwardExtension ?? "");
      hotelForm.setValue("timezone", hotel.timezone);
      hotelForm.setValue("twilioSubAccountId", hotel.twilioSubAccountId ?? "");

      const fromPhone = localStorage.getItem("fromPhone");
      if (fromPhone) {
        messageForm.setValue("fromNumber", fromPhone);
      }
    };

    setup();
  }, []);

  const submitMessage = async (data: MessageForm) => {
    setHistory((prev) => [...prev, { role: "user", content: data.content }]);
    messageForm.setValue("content", "");
    localStorage.setItem("fromPhone", data.fromNumber);

    let phoneCallId = incomingPhoneId;

    if (!incomingPhoneId) {
      phoneCallId = (
        await createIncomingPhone.mutateAsync({
          hotelId,
          fromNumber: data.fromNumber,
        })
      ).id;

      setIncomingPhoneId(phoneCallId);
    }

    if (!phoneCallId) {
      throw new Error("No phone call id");
    }

    const response = await sendMessage.mutateAsync({
      phoneCallId,
      ...data,
    });

    setHistory((prev) => [
      ...prev,
      { role: "assistant", content: `${response.content}` },
    ]);
  };

  const reset = async () => {
    const id = await register.mutateAsync({ hotelId });

    setTab("chat");
    setCallSid(id.id);
    setHistory([
      {
        role: "assistant",
        content: id.hotel.hotelPrompts?.[0]?.initialMessage,
      },
    ]);

    hotelForm.setValue("name", id.hotel.name);
    hotelForm.setValue("address", id.hotel.address);
    hotelForm.setValue("phone", id.hotel.phone);
    hotelForm.setValue("lat", id.hotel.lat);
    hotelForm.setValue("lng", id.hotel.lng);
  };

  if (register.isPending) {
    return <div />;
  }

  if (register.error) {
    if (register.error.message === "UNAUTHORIZED") {
      return <Login />;
    }

    return <div>{register.error.message}</div>;
  }

  return (
    callSid && (
      <div className="p-8">
        <div className="text-2xl font-bold">{register.data?.hotel.name}</div>
        <img src="/cat03.jpg" width="120" />
        <div>PHONE: {formatPhoneNumber(register.data?.hotel.phone)}</div>

        <div role="tablist" className="my-8 tabs tabs-boxed max-w-[600px]">
          <button
            type="button"
            role="tab"
            className={clsx("tab", { "tab-active": tab === "chat" })}
            onClick={() => setTab("chat")}
          >
            Chat
          </button>
          <button
            type="button"
            role="tab"
            className={clsx("tab", { "tab-active": tab === "prompts" })}
            onClick={() => setTab("prompts")}
          >
            Prompts
          </button>
          <button
            type="button"
            role="tab"
            className={clsx("tab", { "tab-active": tab === "hotel" })}
            onClick={() => setTab("hotel")}
          >
            Hotel
          </button>
          <button
            type="button"
            role="tab"
            className={clsx("tab", { "tab-active": tab === "history" })}
            onClick={() => setTab("history")}
          >
            History
          </button>
          <button
            type="button"
            role="tab"
            className={clsx("tab", { "tab-active": tab === "users" })}
            onClick={() => setTab("users")}
          >
            Users
          </button>
          <button
            type="button"
            role="tab"
            className={clsx("tab", { "tab-active": tab === "twilio" })}
            onClick={() => setTab("twilio")}
          >
            Twilio
          </button>
        </div>

        {tab === "prompts" && <Prompts onReset={reset} className="mb-8" />}

        {tab === "hotel" && (
          <FormProvider {...hotelForm}>
            <Hotel onReset={reset} className="mb-8" />
          </FormProvider>
        )}

        {tab === "history" && <History className="mb-8" />}

        {tab === "users" && <Users className="mb-8" />}

        {tab === "twilio" && <Twilio className="mb-8" />}

        {tab === "chat" && (
          <>
            <form
              onSubmit={messageForm.handleSubmit(submitMessage)}
              className="flex flex-col gap-2"
            >
              <input
                className="w-40 p-2 input input-bordered"
                type="text"
                placeholder="+15551231212"
                {...messageForm.register("fromNumber", { required: true })}
              />
              <div className="flex gap-2">
                <input
                  className="w-full max-w-[600px] p-2 input input-bordered"
                  type="text"
                  placeholder="Message to send"
                  {...messageForm.register("content", { required: true })}
                />
                <button
                  disabled={sendMessage.isPending}
                  type="submit"
                  className="btn btn-outline"
                >
                  Send
                </button>
              </div>
            </form>
            <div className="flex flex-col-reverse gap-4 mt-4">
              {history.map((msg, i) => (
                <div key={i} className="flex gap-2">
                  <span>{msg.role === "user" ? "👤" : "🤖"}</span>
                  <span>{msg.content}</span>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    )
  );
}
