import { type HTMLProps, useState } from "react";
import GlobalPrompt from "./globalPrompt";
import HotelPrompt from "./hotelPrompt";
import Questions from "./questions";
import Tools from "./tools";
import TransferPrompt from "./transferPrompt";

export default function Hotel({
  onReset,
  ...params
}: {
  onReset: () => void;
} & HTMLProps<HTMLDivElement>) {
  const [currentTab, setCurrentTab] = useState<
    "global" | "hotel" | "transfer" | "questions" | "tools"
  >("global");

  return (
    <div {...params}>
      <div role="tablist" className="tabs tabs-lifted">
        <input
          type="radio"
          role="tab"
          className="tab"
          aria-label="Global"
          checked={currentTab === "global"}
          onChange={() => setCurrentTab("global")}
        />
        <div
          role="tabpanel"
          className="p-6 tab-content bg-base-100 border-base-300 rounded-box"
        >
          <GlobalPrompt onReset={onReset} />
        </div>

        <input
          type="radio"
          role="tab"
          className="tab"
          aria-label="Hotel"
          checked={currentTab === "hotel"}
          onChange={() => setCurrentTab("hotel")}
        />
        <div
          role="tabpanel"
          className="p-6 tab-content bg-base-100 border-base-300 rounded-box"
        >
          <HotelPrompt onReset={onReset} />
        </div>

        <input
          type="radio"
          role="tab"
          className="tab"
          aria-label="Transfer"
          checked={currentTab === "transfer"}
          onChange={() => setCurrentTab("transfer")}
        />
        <div
          role="tabpanel"
          className="p-6 tab-content bg-base-100 border-base-300 rounded-box"
        >
          <TransferPrompt onReset={onReset} />
        </div>

        <input
          type="radio"
          role="tab"
          className="tab"
          aria-label="Questions"
          checked={currentTab === "questions"}
          onChange={() => setCurrentTab("questions")}
        />
        <div
          role="tabpanel"
          className="p-6 tab-content bg-base-100 border-base-300 rounded-box"
        >
          <Questions onReset={onReset} />
        </div>

        <input
          type="radio"
          role="tab"
          className="tab"
          aria-label="Tools"
          checked={currentTab === "tools"}
          onChange={() => setCurrentTab("tools")}
        />
        <div
          role="tabpanel"
          className="p-6 tab-content bg-base-100 border-base-300 rounded-box"
        >
          <Tools />
        </div>
      </div>
    </div>
  );
}
