import GoogleButton from "react-google-button";

export default function Login() {
  return (
    <div className="flex items-center justify-center h-dvh">
      <GoogleButton
        onClick={() => {
          window.location.href = `${
            import.meta.env.VITE_APP_URL
          }/auth/google?returnTo=${window.location.pathname}`;
        }}
      />
    </div>
  );
}
