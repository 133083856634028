import clsx from "clsx";
import dayjs from "dayjs";
import { type HTMLProps, useEffect, useMemo, useState } from "react";
import useRequiredParam from "../hooks/useRequiredParam";
import useServerStream, { type HistoryOutput } from "../hooks/useServerStream";
import { formatPhoneNumber, trpc } from "../utils";
import IncomingCall from "./incomingCall";

export default function History({ ...params }: HTMLProps<HTMLDivElement>) {
  const hotelId = useRequiredParam<{ hotelId: string }>("hotelId");
  const historyStream = useServerStream({ hotelId, eventType: "history" });
  const getHistory = trpc.history.get.useQuery({ hotelId });
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const merged = useMemo(() => {
    const lookup = new Map<string, (typeof historyStream)[0]>();

    for (let i = historyStream.length - 1; i >= 0; i--) {
      const stream = historyStream[i];
      if (!stream) {
        continue;
      }

      const existing = lookup.get(stream.id);

      if (!existing) {
        lookup.set(stream.id, historyStream[i]);
      }
    }

    for (const history of getHistory.data ?? []) {
      if (!lookup.get(history.id)) {
        lookup.set(history.id, history);
      }
    }

    return [...lookup.values()]
      .filter((a) => !!a)
      .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
  }, [historyStream, getHistory.data]);

  useEffect(() => {
    if (!selectedId && merged.length) {
      setSelectedId(merged[0].id);
    }
  }, [merged, selectedId]);

  const selected: HistoryOutput | undefined = useMemo(
    () => merged.find((call) => call.id === selectedId),
    [selectedId, merged],
  );

  return (
    <div {...params}>
      <div className="flex gap-4">
        <div className="flex flex-col flex-shrink-0 gap-2">
          {merged.map((call, i) => (
            <div
              key={i}
              className={clsx("p-2 border rounded cursor-pointer", {
                "bg-slate-400 text-white": selected?.id === call.id,
              })}
              onClick={() => setSelectedId(call.id)}
            >
              <div className="font-bold">
                {formatPhoneNumber(call.fromNumber)}
              </div>
              <div>{dayjs(call.createdAt).format("YYYY-M-D h:mm:ssa")}</div>
            </div>
          ))}
        </div>
        <div className="flex-grow p-4 border rounded">
          {selected && <IncomingCall item={selected} />}
        </div>
      </div>
    </div>
  );
}
