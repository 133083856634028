import { createTRPCReact } from "@trpc/react-query";
import type { AppRouter } from "../server/routes/trpc";

export const trpc = createTRPCReact<AppRouter>();

const phoneRegex = /(\+1)(\d{3})(\d{3})(\d{4})/;
export const formatPhoneNumber = (phone: string | null | undefined) => {
  if (!phone) {
    return "";
  }

  return phone.replace(phoneRegex, "$1 ($2) $3-$4");
};
