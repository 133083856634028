import clsx from "clsx";
import dayjs from "dayjs";
import { type HTMLProps, useEffect, useState } from "react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import { type SubmitHandler, useForm } from "react-hook-form";
import useRequiredParam from "../hooks/useRequiredParam";
import { trpc } from "../utils";

export type PromptForm = {
  systemPrompt: string;
  userPrompt: string;
  initialMessage: string;
  promptUpdateReason: string;
};

export default function HotelPrompt({
  onReset,
  ...params
}: {
  onReset: () => void;
} & HTMLProps<HTMLDivElement>) {
  const hotelId = useRequiredParam<{ hotelId: string }>("hotelId");
  const getPrompts = trpc.prompts.retrieve.useQuery({ hotelId });
  const [showHistory, setShowHistory] = useState(false);
  const [previousVersion, setPreviousVersion] = useState<number | null>(null);
  const [saving, setSaving] = useState(false);
  const getPreviousVersion = trpc.prompts.getPreviousVersion.useQuery(
    {
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      id: previousVersion!,
    },
    {
      enabled: previousVersion !== null,
    },
  );
  const updatePrompts = trpc.prompts.update.useMutation();
  const {
    register: registerForm,
    handleSubmit,
    setValue,
    watch,
    formState: { isDirty },
  } = useForm<PromptForm>({
    mode: "onChange",
    defaultValues: {
      systemPrompt: "",
      userPrompt: "",
      initialMessage: "",
      promptUpdateReason: "",
    },
  });

  const systemPrompt = watch("systemPrompt");
  const userPrompt = watch("userPrompt");
  const initialMessage = watch("initialMessage");

  useEffect(() => {
    if (!isDirty && getPrompts.data) {
      const { initialMessage, systemPrompt, userPrompt } =
        getPrompts.data.prompts;
      setValue("initialMessage", initialMessage);
      setValue("systemPrompt", systemPrompt);
      setValue("userPrompt", userPrompt);
    }
  }, [getPrompts.data, setValue, isDirty]);

  const onUpdatePrompts: SubmitHandler<PromptForm> = async (data) => {
    await updatePrompts.mutateAsync({
      hotelId,
      ...data,
    });

    // let's reset everything
    onReset();
  };

  return (
    <div {...params}>
      <form
        onSubmit={handleSubmit(onUpdatePrompts)}
        className="flex flex-col gap-4"
      >
        {saving && (
          <div className="flex flex-col gap-4">
            <div>
              <div className="font-bold">User transfer message:</div>
              <div className="text-sm">
                <ReactDiffViewer
                  oldValue={getPrompts.data?.prompts.initialMessage ?? ""}
                  newValue={initialMessage}
                  splitView={true}
                  compareMethod={DiffMethod.WORDS}
                />
              </div>
            </div>
            <div>
              <div className="font-bold">Transfer System prompt:</div>
              <div className="text-sm">
                <ReactDiffViewer
                  oldValue={getPrompts.data?.prompts.systemPrompt ?? ""}
                  newValue={systemPrompt}
                  splitView={true}
                  compareMethod={DiffMethod.WORDS}
                />
              </div>
            </div>
            <div>
              <div className="font-bold">Transfer User prompt:</div>
              <div className="text-sm">
                <ReactDiffViewer
                  oldValue={getPrompts.data?.prompts.userPrompt ?? ""}
                  newValue={userPrompt}
                  splitView={true}
                  compareMethod={DiffMethod.WORDS}
                />
              </div>
            </div>
            <div>
              <textarea
                className="w-full h-24 textarea textarea-bordered"
                placeholder="Reason for updating the prompt"
                {...registerForm("promptUpdateReason", {
                  required: "System prompt is required",
                })}
              />
            </div>
            <div className="flex gap-4">
              <button
                type="button"
                className="btn btn-outline btn-warning"
                onClick={() => setSaving(false)}
              >
                cancel
              </button>
              <button type="submit" className="btn btn-outline btn-primary">
                Submit
              </button>
            </div>
          </div>
        )}

        {!saving && (
          <>
            <div>
              <div className="font-bold">Initial message</div>
              <input
                className="w-full h-10 input input-bordered"
                {...registerForm("initialMessage", {
                  required: "initial message is required",
                })}
              />
            </div>

            <div>
              <div className="font-bold">System prompt</div>
              <textarea
                className="w-full textarea textarea-bordered h-52"
                {...registerForm("systemPrompt")}
              />
            </div>
            <div>
              <div className="font-bold">User prompt</div>
              <textarea
                className="w-full textarea textarea-bordered h-52"
                {...registerForm("userPrompt")}
              />
            </div>

            <button
              type="button"
              className="btn"
              onClick={() => setShowHistory((prev) => !prev)}
            >
              {showHistory ? "Hide history" : "Show history"}
            </button>

            {showHistory && (
              <div className="flex gap-4">
                <div>
                  <div
                    className={clsx("py-2 text-sm cursor-pointer px-1", {
                      " text-white bg-black": previousVersion === null,
                    })}
                    onClick={() => setPreviousVersion(null)}
                  >
                    Current
                  </div>
                  {getPrompts.data?.history.map((prompt) => (
                    <div key={prompt.id}>
                      <div
                        className={clsx(
                          "px-1 py-2 text-sm border-t border-b cursor-pointer whitespace-nowrap",
                          {
                            " text-white bg-black":
                              prompt.id === previousVersion,
                          },
                        )}
                        onClick={async () => {
                          setPreviousVersion(prompt.id);
                        }}
                      >
                        {dayjs(prompt.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex flex-col w-full gap-4">
                  <div>
                    {getPreviousVersion.data?.prompts.promptUpdateReason}{" "}
                    {getPreviousVersion.data?.prompts.updatedBy?.email && (
                      <>({getPreviousVersion.data?.prompts.updatedBy?.email})</>
                    )}
                  </div>
                  <div>
                    <div className="font-bold">User transfer message:</div>
                    <div className="text-sm">
                      <ReactDiffViewer
                        oldValue={
                          getPreviousVersion.data?.previous.initialMessage ??
                          getPrompts.data?.prompts.initialMessage ??
                          ""
                        }
                        newValue={
                          getPreviousVersion.data?.prompts.initialMessage ??
                          initialMessage
                        }
                        splitView={true}
                        compareMethod={DiffMethod.WORDS}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="font-bold">Transfer System prompt:</div>
                    <div className="text-sm">
                      <ReactDiffViewer
                        oldValue={
                          getPreviousVersion.data?.previous.systemPrompt ??
                          getPrompts.data?.prompts.systemPrompt ??
                          ""
                        }
                        newValue={
                          getPreviousVersion.data?.prompts.systemPrompt ??
                          systemPrompt
                        }
                        splitView={true}
                        compareMethod={DiffMethod.WORDS}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="font-bold">Transfer User prompt:</div>
                    <div className="text-sm">
                      <ReactDiffViewer
                        oldValue={
                          getPreviousVersion.data?.previous.userPrompt ??
                          getPrompts.data?.prompts.userPrompt ??
                          ""
                        }
                        newValue={
                          getPreviousVersion.data?.prompts.userPrompt ??
                          userPrompt
                        }
                        splitView={true}
                        compareMethod={DiffMethod.WORDS}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="flex gap-4">
              <button
                className="btn btn-outline btn-primary"
                type="button"
                onClick={() => {
                  setSaving(true);
                  getPrompts.refetch();
                }}
              >
                Update prompts
              </button>
            </div>
          </>
        )}
      </form>
    </div>
  );
}
