import { type HTMLProps, useState } from "react";
import Details from "./details";
import Flags from "./flags";

export default function Hotel({
  onReset,
  ...params
}: {
  onReset: () => void;
} & HTMLProps<HTMLDivElement>) {
  const [currentTab, setCurrentTab] = useState<
    "details" | "room" | "registrations" | "flags"
  >("details");

  return (
    <div {...params}>
      <div role="tablist" className="tabs tabs-lifted">
        <input
          type="radio"
          role="tab"
          className="tab"
          aria-label="Details"
          checked={currentTab === "details"}
          onChange={() => setCurrentTab("details")}
        />
        <div
          role="tabpanel"
          className="p-6 tab-content bg-base-100 border-base-300 rounded-box"
        >
          <Details onReset={onReset} />
        </div>

        <input
          type="radio"
          role="tab"
          className="tab"
          aria-label="Flags"
          checked={currentTab === "flags"}
          onChange={() => setCurrentTab("flags")}
        />
        <div
          role="tabpanel"
          className="p-6 tab-content bg-base-100 border-base-300 rounded-box"
        >
          <Flags />
        </div>
      </div>
    </div>
  );
}
