import { type HTMLProps, useEffect, useState } from "react";
import useRequiredParam from "../hooks/useRequiredParam";
import { trpc } from "../utils";

export default function Flags(params: HTMLProps<HTMLDivElement>) {
  const hotelId = useRequiredParam<{ hotelId: string }>("hotelId");
  const getFlags = trpc.flags.get.useQuery(
    { hotelId },
    {
      refetchOnMount: true,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  );
  const updateFlags = trpc.flags.update.useMutation({
    onSuccess: () => {
      getFlags.refetch();
    },
  });
  const [flags, setFlags] = useState<string[]>([]);

  useEffect(() => {
    if (getFlags.data) {
      setFlags(getFlags.data.flags);
    }
  }, [getFlags.data]);

  return (
    getFlags.data && (
      <div {...params}>
        <div className="flex flex-col gap-4">
          {getFlags.data.allFlags.map((flag) => (
            <div key={flag} className="form-control max-w-80">
              <label className="cursor-pointer label">
                <span className="label-text">{flag}</span>
                <input
                  type="checkbox"
                  className="toggle"
                  checked={flags.includes(flag)}
                  onChange={(e) => {
                    setFlags((prev) => {
                      const nextFlags = e.target.checked
                        ? [...prev, flag]
                        : prev.filter((f) => f !== flag);

                      updateFlags.mutate({
                        hotelId,
                        flags: nextFlags,
                      });
                      return nextFlags;
                    });
                  }}
                />
              </label>
            </div>
          ))}
        </div>
      </div>
    )
  );
}
