import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { type HTMLProps, useMemo, useState } from "react";
import { formatPhoneNumber } from "react-phone-number-input";
import type { HistoryOutput } from "../hooks/useServerStream";
import AudioPlayer from "./audioPlayer";

dayjs.extend(duration);

function ContextHistory({
	call,
	...params
}: { call: HistoryOutput["history"][0] } & HTMLProps<HTMLDivElement>) {
	const [hidden, setHidden] = useState(true);

	const localToolCall = useMemo(() => {
		return (
			call.role === "tool" && call.toolCalls.length === 0 && !!call.content
		);
	}, [call]);

	return (
		<div {...params}>
			<div className="font-bold">
				{call.role}{" "}
				{call && call.createdAt
					? dayjs(call.createdAt).format("YYYY-MM-DD HH:mm:ss.SSS")
					: ""}
			</div>
			{localToolCall ? (
				<div>
					<div
						className="text-blue-600 cursor-pointer "
						onClick={() => setHidden((prev) => !prev)}
					>
						Results
					</div>
					{!hidden && <div>{call.content}</div>}
				</div>
			) : (
				<div>{call.content}</div>
			)}
			<div>
				{call.toolCalls.map((tool, idx) => (
					<div className="flex flex-col gap-2" key={idx}>
						<div
							className="text-blue-600 cursor-pointer "
							onClick={() => setHidden((prev) => !prev)}
						>
							{tool.name}
						</div>
						{!hidden && (
							<>
								<div className="font-bold">Arguments</div>
								<div>{tool.arguments}</div>
								{tool.results && (
									<>
										<div className="font-bold">Results</div>
										<div>{tool.results}</div>
									</>
								)}
							</>
						)}
					</div>
				))}
			</div>
		</div>
	);
}

export default function IncomingCall({
	item,
	...params
}: { item: HistoryOutput } & HTMLProps<HTMLDivElement>) {
	const time = useMemo(() => {
		if (item.history.length === 0) {
			return "0m 0s";
		}

		const duration = dayjs.duration(
			item.endedAt
				? dayjs(item.endedAt).diff(item.startedAt, "milliseconds")
				: dayjs(item.history[item.history.length - 1].createdAt).diff(
						item.history[0].createdAt,
						"milliseconds"
				  )
		);

		return `${duration.minutes()}m ${duration.seconds()}s`;
	}, [item]);

	const sorted = useMemo(() => {
		return item.history
			.filter((a) => !a.aborted)
			.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
	}, [item.history]);

	return (
		<div {...params}>
			<div className="flex items-center justify-center gap-8 pb-2 mb-2 border-b">
				<span className="flex-shrink-0">
					{formatPhoneNumber(item.fromNumber)}
				</span>{" "}
				➡️
				<span className="flex-shrink-0">
					{formatPhoneNumber(item.toNumber)}
				</span>
				<span>{time}</span>
				{item.recordingUrl && (
					<span className="">
						<AudioPlayer url={item.recordingUrl} />
					</span>
				)}
			</div>

			<div className="flex flex-col gap-4">
				{sorted.map((event, i) => (
					<ContextHistory key={i} call={event} />
				))}
			</div>
		</div>
	);
}
