import { createTheme, JsonInput, MantineProvider } from "@mantine/core";
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useState, type HTMLProps } from "react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import { Controller, useForm, type SubmitHandler } from "react-hook-form";
import useRequiredParam from "../hooks/useRequiredParam";
import { trpc } from "../utils";

export type QAForm = {
  questions: string;
  updateReason: string;
};

const theme = createTheme({
  fontFamily: "Montserrat, sans-serif",
  defaultRadius: "md",
});

export default function Questions({
  onReset,
  ...params
}: {
  onReset: () => void;
} & HTMLProps<HTMLDivElement>) {
  const hotelId = useRequiredParam<{ hotelId: string }>("hotelId");
  const updateQuestions = trpc.questions.update.useMutation();
  const [showHistory, setShowHistory] = useState(false);
  const [previousVersion, setPreviousVersion] = useState<number | null>(null);
  const [saving, setSaving] = useState(false);
  const getPreviousVersion = trpc.questions.getPreviousVersion.useQuery(
    {
      // biome-ignore lint/style/noNonNullAssertion: <explanation>
      id: previousVersion!,
    },
    {
      enabled: previousVersion !== null,
    },
  );
  const getQuestions = trpc.questions.retrieve.useQuery({ hotelId });
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    register: registerForm,
    formState: { isDirty },
  } = useForm<QAForm>({
    mode: "onChange",
    defaultValues: {
      questions: "",
      updateReason: "",
    },
  });

  const questions = watch("questions");

  useEffect(() => {
    if (!isDirty && getQuestions.data) {
      setValue("questions", getQuestions.data.questions);
    }
  }, [getQuestions.data, setValue, isDirty]);

  const onQuestionsUpdate: SubmitHandler<QAForm> = async (data) => {
    await updateQuestions.mutateAsync({
      hotelId,
      ...data,
    });

    // let's reset everything
    onReset();
  };

  return (
    <div {...params}>
      <MantineProvider theme={theme}>
        <form
          onSubmit={handleSubmit(onQuestionsUpdate)}
          className="flex flex-col gap-4"
        >
          {saving && (
            <div className="flex flex-col gap-4">
              <div>
                <div className="font-bold">Questions:</div>
                <div className="text-sm">
                  <ReactDiffViewer
                    oldValue={getQuestions.data?.questions ?? ""}
                    newValue={questions}
                    splitView={true}
                    compareMethod={DiffMethod.WORDS}
                  />
                </div>
              </div>

              <div>
                <textarea
                  className="w-full h-24 textarea textarea-bordered"
                  placeholder="Reason for updating the questions"
                  {...registerForm("updateReason", {
                    required: "Update reason is required",
                  })}
                />
              </div>
              <div className="flex gap-4">
                <button
                  type="button"
                  className="btn btn-outline btn-warning"
                  onClick={() => setSaving(false)}
                >
                  cancel
                </button>
                <button type="submit" className="btn btn-outline btn-primary">
                  Submit
                </button>
              </div>
            </div>
          )}

          {!saving && (
            <>
              <div className="w-full">
                <Controller
                  control={control}
                  name="questions"
                  rules={{ required: "Question is required" }}
                  render={({ field }) => (
                    <JsonInput
                      size="xs"
                      placeholder="Textarea will autosize to fit the content"
                      validationError="Invalid JSON"
                      formatOnBlur
                      autosize
                      minRows={4}
                      {...field}
                      classNames={{
                        error: "text-red-600 font-bold",
                        input: "w-full textarea textarea-bordered",
                      }}
                    />
                  )}
                />
              </div>

              <button
                type="button"
                className="btn"
                onClick={() => setShowHistory((prev) => !prev)}
              >
                {showHistory ? "Hide history" : "Show history"}
              </button>

              {showHistory && (
                <div className="flex gap-4">
                  <div>
                    <div
                      className={clsx("py-2 text-sm cursor-pointer px-1", {
                        " text-white bg-black": previousVersion === null,
                      })}
                      onClick={() => setPreviousVersion(null)}
                    >
                      Current
                    </div>
                    {getQuestions.data?.history.map((questions) => (
                      <div key={questions.id}>
                        <div
                          className={clsx(
                            "px-1 py-2 text-sm border-t border-b cursor-pointer whitespace-nowrap",
                            {
                              " text-white bg-black":
                                questions.id === previousVersion,
                            },
                          )}
                          onClick={async () => {
                            setPreviousVersion(questions.id);
                          }}
                        >
                          {dayjs(questions.createdAt).format(
                            "YYYY-MM-DD HH:mm:ss",
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="flex flex-col w-full gap-4">
                    <div>
                      {getPreviousVersion.data?.questions.updateReason}{" "}
                      {getPreviousVersion.data?.questions.updatedBy?.email && (
                        <>
                          ({getPreviousVersion.data?.questions.updatedBy?.email}
                          )
                        </>
                      )}
                    </div>
                    <div>
                      <div className="font-bold">Questions:</div>
                      <div className="text-sm">
                        <ReactDiffViewer
                          oldValue={
                            getPreviousVersion.data?.previous.questions ??
                            getQuestions.data?.questions ??
                            ""
                          }
                          newValue={
                            getPreviousVersion.data?.questions.questions ??
                            questions
                          }
                          splitView={true}
                          compareMethod={DiffMethod.WORDS}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="flex gap-4">
                <button
                  className="btn btn-outline btn-primary"
                  type="button"
                  onClick={() => {
                    setSaving(true);
                    getQuestions.refetch();
                  }}
                >
                  Update questions
                </button>
              </div>
            </>
          )}
        </form>
      </MantineProvider>
    </div>
  );
}
