import type { HTMLProps } from "react";
import { type SubmitHandler, useFormContext } from "react-hook-form";
import timezones from "timezones-list";
import type { HotelForm } from "../app";
import useRequiredParam from "../hooks/useRequiredParam";
import { trpc } from "../utils";

export default function Details({
  onReset,
  ...params
}: {
  onReset: () => void;
} & HTMLProps<HTMLDivElement>) {
  const hotelId = useRequiredParam<{ hotelId: string }>("hotelId");
  const updateHotel = trpc.hotel.update.useMutation();
  const resetHotelToDefault = trpc.hotel.reset.useMutation();
  const getTwilioAccounts = trpc.twilio.retrieve.useQuery();

  const { register: registerForm, handleSubmit } = useFormContext<HotelForm>();

  const onUpdateHotel: SubmitHandler<HotelForm> = async (data) => {
    await updateHotel.mutateAsync({ hotelId, ...data });

    // let's reset everything
    onReset();
  };

  const resetHotel = async () => {
    if (window.confirm("Are you sure you want to reset the hotel?")) {
      await resetHotelToDefault.mutateAsync({ hotelId });

      // let's reset everything
      onReset();
    }
  };

  if (!getTwilioAccounts.data) {
    return null;
  }

  return (
    <div {...params}>
      <form
        onSubmit={handleSubmit(onUpdateHotel)}
        className="flex flex-col gap-4"
      >
        <div>
          <div className="font-bold">Name</div>
          <input
            className="w-full h-10 input input-bordered"
            {...registerForm("name", {
              required: "hotel name is required",
            })}
          />
        </div>

        <div>
          <div className="font-bold">Timezone</div>
          <select
            className="w-full select select-bordered"
            {...registerForm("timezone", {
              required: "hotel timezone is required",
            })}
          >
            <option value="">Select timezone</option>
            {timezones.map((option) => (
              <option key={option.tzCode} value={option.tzCode}>
                {option.label}
              </option>
            ))}
          </select>
        </div>

        <div>
          <div className="font-bold">Address</div>
          <input
            className="w-full input input-bordered"
            {...registerForm("address", {
              required: "hotel address is required",
            })}
          />
        </div>
        <div>
          <div className="font-bold">Phone</div>
          <input
            className="w-full input input-bordered"
            {...registerForm("phone", {
              required: "Hotel phone is required",
            })}
          />
        </div>
        <div>
          <div className="font-bold">Twilio sub account</div>
          <select
            {...registerForm("twilioSubAccountId", {
              required: "Sub account is required",
            })}
            className="w-full select select-bordered"
          >
            <option value="">Select sub account</option>
            {getTwilioAccounts.data?.map((value) => (
              <option key={value.id} value={value.id}>
                {value.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <div className="font-bold">Front desk phone</div>
          <input
            className="w-full input input-bordered"
            {...registerForm("forwardPhone")}
          />
        </div>
        <div>
          <div className="font-bold">Front desk extension</div>
          <div>(w is a half second pause ie ww123)</div>
          <input
            className="w-full input input-bordered"
            {...registerForm("forwardExtension")}
          />
        </div>
        <div className="flex gap-4">
          <div>
            <div className="font-bold">Lat</div>
            <input
              className="w-full input input-bordered"
              {...registerForm("lat", {
                required: "Hotel lat is required",
              })}
            />
          </div>
          <div>
            <div className="font-bold">Lng</div>
            <input
              className="w-full input input-bordered"
              {...registerForm("lng", {
                required: "Hotel long is required",
              })}
            />
          </div>
        </div>
        <div className="flex gap-4">
          <button className="btn btn-error" onClick={resetHotel} type="button">
            Reset hotel
          </button>
          <button className="btn btn-outline btn-primary" type="submit">
            Update hotel
          </button>
        </div>
      </form>
    </div>
  );
}
