import type { HTMLProps } from "react";
import { useForm } from "react-hook-form";
import { trpc } from "../utils";

export default function Users({ ...params }: {} & HTMLProps<HTMLDivElement>) {
  const getUsers = trpc.users.retrieve.useQuery();
  const deleteUser = trpc.users.delete.useMutation({
    onSettled() {
      getUsers.refetch();
    },
  });
  const createUser = trpc.users.create.useMutation({
    onSettled() {
      getUsers.refetch();
    },
  });
  const userForm = useForm<{ email: string }>();

  return (
    <div {...params}>
      <div className="overflow-x-auto">
        <table className="table">
          {/* head */}
          <thead>
            <tr>
              <th>Email</th>
              <th>Role</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {/* row 1 */}
            {getUsers.data?.map((user) => (
              <tr key={user.email}>
                <td>{user.email}</td>
                <td>{user.role}</td>
                <td>
                  <button
                    type="button"
                    onClick={() => {
                      if (window.confirm("Are you sure?")) {
                        deleteUser.mutate({ email: user.email });
                      }
                    }}
                    className="btn btn-sm btn-error"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <form
          className="flex gap-2 mt-4"
          onSubmit={userForm.handleSubmit((data) => {
            userForm.reset();
            createUser.mutate({ email: data.email });
          })}
        >
          <input
            type="text"
            placeholder="User email"
            className="w-full max-w-xs input input-bordered"
            {...userForm.register("email", { required: true })}
          />
          <button className="btn" type="submit">
            Add user
          </button>
        </form>
      </div>
    </div>
  );
}
